import React, { useState } from "react";

export default ({ youtubeId }) => {
  const [loading, setLoading] = useState(true);
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      {loading && (
        <div
          css={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightgray",
            color: "black",
          }}
        >
          Loading...
        </div>
      )}
      <iframe
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        onLoad={() => {
          setLoading(false);
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allowFullScreen={true}
      />
    </div>
  );
};
