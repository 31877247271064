import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import { Link } from "gatsby";
import { videos } from "../data/index";
import YouTube from "../components/youtube";
import css from "@emotion/css";

const Music = () => {
  return (
    <Layout>
      <SEO title="Music" />
      <h1>Videos</h1>

      {videos.map(v => (
        <div
          key={v.url}
          css={css`
            margin-bottom: 32px;
          `}
        >
          {/* <div>{v.name}</div> */}
          <YouTube youtubeId={v.url.split("watch?v=")[1]} />
        </div>
      ))}
      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  );
};

export default Music;
